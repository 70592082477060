import React from 'react';
import {InputBox, Select} from '../../library/styled-components';
import {addCommas, addIdNumHyphen, numberOnly, stringNumberToInt} from '../../bin/common';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import axiosInstance from '../../library/axios-index';

const fieldsOrder = ['user_nm'
    , 'id_num',
    'phone_num',
    'personnel_num',
    'const_manager_yn',
    'team_seq',
    'rank_seq',
    'salary_amt',
    'join_dt'];

const salaryTypeOptions = [
    {value: 'Y', label: '연봉'},
    {value: 'M', label: '월급'},
    {value: 'D', label: '일급'}
];

const constManagerYnOptions = [
    {value: 'Y', label: '예'},
    {value: 'N', label: '아니오'},
];

const hireTypeOptions = [
    {value: 'F', label: '정규직'},
    {value: 'T', label: '계약직'},
    {value: 'D', label: '일용직'}
];
const NewEmployForm = ({formRef, newEmployComplete, selectedLaborEmploy, ...props}) => {

    const {register, control, handleSubmit, reset, setValue, getValues, setFocus, formState: {errors}} = useForm({
        defaultValues: {
            user_nm: selectedLaborEmploy?.user_nm,
            id_num: selectedLaborEmploy?.id_num,
            phone_num: selectedLaborEmploy?.phone_num,
            personnel_num: selectedLaborEmploy?.personnel_num,
            addr1: selectedLaborEmploy?.addr1,
            addr2: selectedLaborEmploy?.addr2,
            const_manager_yn: {value: 'N', label: '아니오'},
            team_seq: selectedLaborEmploy?.team_seq,
            rank_seq: selectedLaborEmploy?.rank_seq,
            hire_type: {value: 'F', label: '정규직'},
            salary_type: {value: 'Y', label: '연봉'},
            salary_amt: 0,
            bank_nm: selectedLaborEmploy?.bank_nm && {
                value: selectedLaborEmploy?.bank_nm,
                label: selectedLaborEmploy?.bank_nm
            },
            bank_acct: selectedLaborEmploy?.bank_acct,
            join_dt: ''
        }
    });


    const onSubmit = async (e) => {
    
        e.salary_amt = stringNumberToInt(e.salary_amt);
        e.phone_num = stringNumberToInt(e.phone_num);
        e.bank_nm = !getValues('bank_nm') ? null : getValues('bank_nm')?.value;
        e.const_manager_yn = e.const_manager_yn['value'];
        e.hire_type = e.hire_type['value'];
        e.rank_seq = e.rank_seq['value'];
        e.salary_type = e.salary_type['value'];
        e.team_seq = e.team_seq['value'];


        const token = localStorage.getItem('admin_token');

        const {data: {result: done}} = await axiosInstance.post(`/company/newEmploy`, {
            lang: 'ko',
            uuid: 'string',
            token,
            user_seq: selectedLaborEmploy?.user_seq,
            ...e
        });

        newEmployComplete();
    };
    // 신규 등록 오류

    const onError = (errors) => {

        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = fieldsOrder.find(key => errorKeys.includes(key));
            setFocus(firstError);

            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };

    return (
        <FieldGroup ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}
                    className="new-employ-form">
            <InputBox>
                <h6>이름 <span>*</span></h6>
                <input name="user_nm"
                       type="text" {...register('user_nm', {
                    validate: {
                        register: (value) => value !== '' || '이름을 입력해 주세요.',
                        noSpaces: (value) => value?.trim() !== '' || '이름을 입력해 주세요.',
                    }
                })}  />
            </InputBox>
            <InputBox>
                <h6>주민등록번호 <span>*</span></h6>
                <input name="id_num" maxLength={14}
                       type="text" {...register('id_num', {
                    required: '주민등록번호를 입력해 주세요', pattern: {
                        value: /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-8][0-9]{6}$/,
                        message: '주민등록번호 형식이 올바르지 않습니다'
                    }
                })} onChange={e => e.target.value = addIdNumHyphen(e.target.value)}/>
            </InputBox>

            <InputBox>
                <h6>연락처 <span>*</span></h6>
                <input name="phone_num"
                       maxLength={11}
                       type="text" {...register('phone_num', {
                    required: '연락처를 입력해 주세요', pattern: {
                        value: /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{5}$/,
                        message: '연락처 형식이 올바르지 않습니다'
                    }
                })} onChange={(e) => {
                    numberOnly(e);
                }}/>
            </InputBox>

            <InputBox>
                <h6>사원번호 <span>*</span></h6>
                <input name="personnel_num"
                       {...register('personnel_num', {
                           validate: {
                               register: (value) => value !== '' || '사원번호를 입력해 주세요.',
                               noSpaces: (value) => value?.trim() !== '' || '사원번호를 입력해 주세요.',
                           }
                       })}
                       maxLength={25}/>
            </InputBox>

            <InputBox fulled>
                <h6>주소 <span>*</span></h6>
                <h5>{getValues('addr1')} {getValues('addr2')}</h5>
            </InputBox>

            <InputBox style={{marginTop: '3px'}}>
                <h6>현장 관리자 여부 <span>*</span></h6>
                <Controller
                    name="const_manager_yn"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <Select
                            {...field}
                            options={props?.constManagerYnOptions}
                        />
                    )}
                />

            </InputBox>
            {/*<InputBox></InputBox>*/}

            <InputBox>
                <h6>부서 <span>*</span></h6>
                <Controller name={'team_seq'} control={control}
                            rules={{
                                required: '부서를 선택해주세요'
                            }}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            boxShadow: 'none',
                                        })
                                    }}
                                    placeholder={'부서를 선택해주세요'}
                                    options={props?.teams.map((e) => {
                                        return {value: e.team_seq, label: e.team_nm};
                                    })}
                                />
                            )}></Controller>

            </InputBox>

            <InputBox>
                <h6>직급 <span>*</span></h6>
                <Controller name={'rank_seq'} control={control}
                            rules={{required: '직급을 선택해주세요'}}
                            render={({field}) => (
                                <Select
                                    {...field}

                                    placeholder={'직급을 선택해주세요'}
                                    options={props.ranks.map((e) => {
                                        return {
                                            value: e.rank_seq,
                                            label: e.position_nm
                                        };
                                    })}
                                />
                            )}></Controller>


            </InputBox>

            <InputBox>
                <h6>고용형태 <span>*</span></h6>
                <Controller
                    name="hire_type"
                    control={control}
                    rule={{required: true, message: '고용형태를 선택해주세요'}}
                    render={({field}) => (
                        <Select
                            {...field}
                            options={hireTypeOptions}
                        />
                    )}
                />
            </InputBox>

            <InputBox>
                <h6>급여 <span>*</span></h6>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr',
                    columnGap: '3px'
                }}>

                    <Controller
                        name="salary_type"
                        control={control}
                        rule={{required: true}}
                        render={({field}) => (
                            <Select
                                {...field}
                                options={salaryTypeOptions}
                            />
                        )}
                    />

                    <input name="salary_amt"
                           {...register('salary_amt', {
                               required: '급여를 입력해 주세요'
                           })}
                           onChange={(e) => {
                               numberOnly(e);
                               e.target.value = stringNumberToInt(e.target.value);
                               e.target.value = addCommas(e.target.value);
                           }}/>
                </div>
            </InputBox>
            <InputBox>
                <h6>은행 </h6>
                <h5>{getValues('bank_nm')?.label} {getValues('bank_acct')}</h5>
            </InputBox>
            {/*<InputBox>*/}
            {/*    <h6>은행</h6>*/}

            {/*    <Controller*/}
            {/*        name="bank_nm"*/}
            {/*        control={control}*/}
            {/*        render={({field}) => (*/}
            {/*            <Select*/}
            {/*                {...field}*/}
            {/*                placeholder={'은행을 선택해주세요'}*/}
            {/*                options={props?.bankNames}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}

            {/*</InputBox>*/}

            {/*<InputBox>*/}
            {/*    <h6>계좌번호</h6>*/}
            {/*    <input name="bank_acct"    {...register('bank_acct', {})} onChange={e => numberOnly(e)} maxLength={20}/>*/}
            {/*</InputBox>*/}

            <InputBox fulled>
                <h6>입사일 <span>*</span></h6>
                <input name="join_dt" type="date"    {...register('join_dt', {
                    required: {
                        value: true,
                        message: '입사일을 입력해주세요'
                    }
                })}
                       onKeyDown={(e) => e.preventDefault()}/>
            </InputBox>
        </FieldGroup>
    );
};
const FieldGroup = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 14px;
`;
export default NewEmployForm;
