import styled from '@emotion/styled';
import {Header} from '../../compontents/Header';
import React, {useCallback, useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import Pagination from 'react-js-pagination';
import SideModal from '../../compontents/SideModal';
import {PagingFooter} from '../../compontents/PagingFooter';
import axiosInstance from '../../library/axios-index';
import AxiosNavigate from '../../library/axios-navigate';
import ImageEnlargedModal from '../../compontents/ImageEnlagedModal';
import SearchBox from '../../compontents/Search';
import moment from 'moment';
import {BlockScrolling} from '../../bin/common';
import PagesList from '../../compontents/PagesList';
import {useRecoilValue} from 'recoil';
import {planState} from '../../library/recoil/atoms/planState';
import EmptyContainer from '../../compontents/EmptyContainer';

const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },
    })
};

const options = [
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
];

function Company() {
    const planDataList = useRecoilValue(planState);

    // pagination
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(5);
    const [searchTxt, setSearchTxt] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    // 회사 정보
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [opendSelectedCompany, setOpendSelectedCompany] = useState(false);

    //이미지 확대 모달
    const [opendImage, setOpendImage] = useState(false);
    const [opendImageIndex, setOpendImageIndex] = useState(0);
    const [imageArr, setImageArr] = useState([]);

    BlockScrolling(opendSelectedCompany);


    const handleCompanyInfo = useCallback((corp_seq) => {
        setOpendSelectedCompany(true);

        const selectCompany = company.find(e => e.corp_seq === corp_seq);

        const imgArr = [];

        setOpendImageIndex(0);

        imgArr.push({src: selectCompany.logo_img});
        imgArr.push({src: selectCompany.stamp_img});
        imgArr.push({src: selectCompany.biz_reg_img});
        setImageArr(imgArr);

        setSelectedCompany(selectCompany);
    }, [company]);

    const handleSearchTxt = useCallback(async (value) => {

        await getCompanyList(value, 1, limit);
        setSearchTxt(value);

    }, [searchTxt, page, limit]);

    const handlePage = useCallback(async (value) => {

        await getCompanyList(searchTxt, value, limit);

        setPage(value);

    }, [searchTxt, page, limit]);

    const handleLimit = useCallback(async (e) => {

        await getCompanyList(searchTxt, page, e.value);

        setLimit(e.value);

    }, [searchTxt, page, limit]);

    const handleConfirm = useCallback(async (company) => {

        if (company.confirm_yn === 'Y') {
            return alert('이미 가입이 승인된 회사 입니다');
        }

        try {
            const {data: {result}} = await axiosInstance.post(`/company/confirmSignUp`, {

                corp_seq: company.corp_seq,
                confirm_yn: 'Y'

            });
            alert('승인되었습니다');
        } catch (e) {
            return alert(e);
        }

        await getCompanyList(searchTxt, page, limit);
        // setSelectedCompany(e => _company.find(e => e.site_seq === selectedCompany.site_seq))

    }, [searchTxt, page, limit]);
    const getCompanyList = async (search_txt, page, limit) => {

        setPage(page);
        setLimit(limit);

        try {
            const {data: {result: {list, count}}} = await axiosInstance.post(`/company/list`, {
                search_txt,
                page,
                limit
            });

            list?.map((company) => {

                if (company.biz_reg_num) {
                    company.biz_reg_num = company?.biz_reg_num?.slice(0, 3) + '-' +
                        company?.biz_reg_num?.slice(3, 5) + '-' +
                        company?.biz_reg_num?.slice(5, company?.biz_reg_num?.length);
                }

                if (company.corp_reg_num) {
                    company.corp_reg_num = company.corp_reg_num?.slice(0, 6) + '-' + company.corp_reg_num?.slice(6, company.corp_reg_num?.length);
                }
            });
            setCompany(list);
            setTotalCount(count);

        } catch (e) {
            return alert('회사 정보를 가져오기를 실패 하였습니다. 다시 시도해 주세요');
        }

    };

    useEffect(() => {

        (async () => {
            try {
                await getCompanyList('', 1, options[0].value);
            } catch (e) {

            }

        })();

    }, []);


    const handleImageIndex = (direction) => {
        if (direction === 'right') {
            if (opendImageIndex < imageArr?.length - 1) {
                setOpendImageIndex((opendImageIndex) => opendImageIndex + 1);
            }
        } else {
            if (opendImageIndex > 0) {
                setOpendImageIndex((opendImageIndex) => opendImageIndex - 1);
            }
        }
    };

    const dataFilter = (id) => {
        const filterData = planDataList.filter((e, i) => {
            if (e.dtl_cd_id === id) {
                return e.dtl_cd_nm;
            }
        });
        return filterData[0].dtl_cd_nm;
    };

    return (
        <AxiosNavigate>
            <div>
                <Header>
                    <div>
                        회사관리
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SearchBox placeholder={'회사명 검색'}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                           if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
                                               alert('법안명을 입력해 주세요');
                                               setSearchValue(e.target.value.trim());
                                               return;
                                           }
                                           setSearchValue(e.target.value.trim());
                                           handleSearchTxt(e.target.value.replaceAll(' ', ''));
                                       }
                                   }
                                   }
                                   value={searchValue} onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        ></SearchBox>

                    </div>
                </Header>

                <TableContainer>
                    <TableWrapper>
                        <Table>
                            <thead>
                            <tr>
                                <th>법인명</th>
                                <th>아이디</th>
                                <th>대표이사명</th>
                                <th>사업자등록번호</th>
                                <th>법인등록번호</th>
                                <th>대표전화</th>
                                <th>플랜</th>
                                <th>할인정책</th>
                                <th width={'170px'}>할인기간</th>
                                <th>가입일</th>
                            </tr>
                            </thead>
                            <tbody>
                            {company && company.length === 0 ? <tr style={{height: '500px'}}>
                                <td colSpan={9}>
                                    <EmptyContainer falseText={'등록된 회사가'}
                                                    style={{background: '#fff'}}></EmptyContainer>
                                </td>
                            </tr> : company.map((e, i) => <tr key={i}>
                                <td>

                                    <div style={{
                                        minWidth: '162px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        {e.corp_nm}
                                        <Button onClick={() => {
                                            handleCompanyInfo(e.corp_seq);
                                        }} style={{marginLeft: '8px'}}>상세보기</Button>
                                    </div>
                                </td>
                                <td>{e.corp_id}</td>
                                <td>
                                    {e.ceo_nm}
                                </td>
                                <td>
                                    {
                                        e.biz_reg_num
                                    }
                                </td>
                                <td>
                                    {
                                        e.corp_reg_num
                                    }
                                </td>
                                <td>{e.phone_num}</td>
                                <td>
                                    {
                                        planDataList && dataFilter(e.plan_id)
                                    }
                                </td>
                                <td>
                                    {e.dscnt_title}
                                </td>
                                <td>
                                    {e.start_dt && e.end_dt ? moment.utc(e.start_dt).format('YYYY년 M월') + ' ~ ' + moment.utc(e.end_dt).format('YYYY년 M월') : ''}

                                </td>
                                <td>
                                    {
                                        moment.utc(e.crt_dt).format('YYYY년 M월 D일')
                                    }
                                </td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </TableContainer>

                <PagingFooter>
                    <Pagination
                        prevPageText={(<Icon src="/assets/icons/chevron-left.svg"/>)}
                        nextPageText={(<Icon src="/assets/icons/chevron-right.svg"/>)}
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount === 0 ? 1 : totalCount}
                        pageRangeDisplayed={5}
                        hideFirstLastPages={true}
                        onChange={handlePage}
                    />


                    <PagesList options={options}
                               onChange={handleLimit}
                    ></PagesList>

                </PagingFooter>
                <SideModal
                    opend={opendSelectedCompany}
                    closeModal={() => {
                        setOpendSelectedCompany(false);
                    }}
                    header={'회사 상세정보'}
                    buttons={<>
                        {/*<ModalButton onClick={() => {*/}
                        {/*  setOpendModifyCompany(true)*/}
                        {/*}}>수정하기</ModalButton>*/}
                    </>}
                >

                    <Content>
                        <figure>
                            <h6>법인명</h6>
                            <h5>{selectedCompany.corp_nm}</h5>
                        </figure>
                        <figure>
                            <h6>아이디</h6>
                            <h5>{selectedCompany.corp_id}</h5>
                        </figure>
                        <figure>
                            <h6>대표이사명</h6>
                            <h5>{selectedCompany.ceo_nm}</h5>
                        </figure>
                        <figure>
                            <h6>사업자구분</h6>
                            <h5>{selectedCompany.biz_division === 'C' ? '법인' : '개인'}</h5>
                        </figure>
                        <figure>
                            <h6>업태</h6>
                            <h5>{selectedCompany.biz_type}</h5>
                        </figure>
                        <figure>
                            <h6>종목</h6>
                            <h5>{selectedCompany.biz_sector}</h5>
                        </figure>
                        <figure>
                            <h6>사업자등록번호</h6>
                            <h5>
                                {
                                    // selectedCompany.biz_reg_num.slice(0,3)+'-'+
                                    // selectedCompany.biz_reg_num.slice(3,5)+'-'+
                                    // selectedCompany.biz_reg_num.slice(5,selectedCompany.biz_reg_num.length)
                                    selectedCompany.biz_reg_num
                                }
                            </h5>
                        </figure>
                        <figure>
                            <h6>법인등록번호</h6>
                            <h5>
                                {
                                    // selectedCompany.corp_reg_num.slice(0, 6)+'-'+
                                    // selectedCompany.corp_reg_num.slice(6, selectedCompany.corp_reg_num.length)
                                    selectedCompany.corp_reg_num
                                }
                            </h5>
                        </figure>
                        <hr/>
                        <figure>
                            <h6>대표전화</h6>
                            <h5>{selectedCompany.phone_num}</h5>
                        </figure>
                        <figure>
                            <h6>팩스번호</h6>
                            <h5>{selectedCompany.fax_num}</h5>
                        </figure>
                        <figure>
                            <h6>사업장 소재지</h6>
                            <h5 style={{width: '200px', flexWrap: 'wrap'}}>{selectedCompany.addr1}</h5>
                        </figure>
                        <figure>
                            <h6>상세 주소</h6>
                            <h5>{selectedCompany.addr2}</h5>
                        </figure>
                        <hr/>
                        <figure>
                            <h6>관리자</h6>
                            <h5>{selectedCompany.manager_nm}</h5>
                        </figure>
                        <figure>
                            <h6>이메일</h6>
                            <h5>{selectedCompany.email_addr}</h5>
                        </figure>
                        <figure>
                            <h6>휴대폰번호(관리자)</h6>
                            <h5>{selectedCompany.manager_phone_num}</h5>
                        </figure>
                        <hr/>

                        {/*<figure></figure>*/}

                        <ImageList>
                            <ImageCard>
                                <h6>
                                    회사로고
                                </h6>

                                {!selectedCompany.logo_img ? <span>등록된<br/>사진이 없습니다</span> :
                                    <ImageItem src={selectedCompany.logo_img}
                                               onClick={() => {
                                                   setOpendImageIndex(0);
                                                   setOpendImage(true);
                                               }}>
                                    </ImageItem>}
                            </ImageCard>
                            <ImageCard>
                                <h6>
                                    법인인감
                                </h6>
                                {!selectedCompany.stamp_img ? <span>등록된<br/>사진이 없습니다</span> :
                                    <ImageItem src={selectedCompany.stamp_img}
                                               onClick={() => {
                                                   setOpendImageIndex(1);
                                                   setOpendImage(true);
                                               }}>
                                    </ImageItem>}

                            </ImageCard>
                            <ImageCard>
                                <h6>
                                    사업자등록증
                                </h6>
                                {!selectedCompany.biz_reg_img ? <span>등록된<br/>사진이 없습니다</span> :
                                    <ImageItem src={selectedCompany.biz_reg_img}
                                               onClick={() => {
                                                   setOpendImageIndex(2);
                                                   setOpendImage(true);
                                               }}>
                                    </ImageItem>}
                            </ImageCard>
                        </ImageList>

                    </Content>
                </SideModal>
            </div>

            <ImageEnlargedModal
                opend={opendImage}
                closeModal={() => {
                    setOpendImage(false);
                }}
                handleImageIndex={handleImageIndex}
                imageIndex={opendImageIndex + 1}
                imageLength={(imageArr[0]?.src === null ? 0 : 1) + (imageArr[1]?.src === null ? 0 : 1) + (imageArr[2]?.src === null ? 0 : 1)}
                // imageLength={imageArr?.length}
            >

                <div style={{height: 'calc(100vh - 62px)', width: 'calc(100vw - 200px)'}}>
                    <Header style={{
                        color: 'white', display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        lineHeight: '37px',
                        fontSize: '25px', padding: '0', marginBottom: '10px'
                    }}> {opendImageIndex === 0 ? '회사로고' : opendImageIndex === 1 ? '법인인감' : '사업자등록증'}</Header>
                    <div style={{height: 'calc(100% - 78px)', position: 'relative', overflow: 'auto'}}>

                        <img
                            src={imageArr[opendImageIndex]?.src}
                            alt="EnlargedImage" style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            position: 'absolute',
                            top: '0',
                            bottom: '0',
                            right: '0',
                            left: '0',
                            margin: 'auto'
                        }}/>
                    </div>
                </div>
            </ImageEnlargedModal>


        </AxiosNavigate>
    );
}

export const FormButton = styled.div`
    cursor: pointer;
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #708090;
    color: aliceblue;
    border: .5px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    //width: fit-content;
    margin-top: 12px;
    margin-bottom: 15px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    margin-right: 16px;
    height: 34px;
    box-sizing: border-box;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 6px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 100%;

`;

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;

    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

`;

const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;

const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }

`;


const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;

const Content = styled.div`
    figure {
        margin: 0;
        display: flex;
        align-items: center;
        height: 34px;
    }

    h6, h5 {
        margin: 0;
    }

    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        width: 140px;
    }

    h5 {
        color: rgba(0, 0, 0, 0.92);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 12px 0;
    }
`;

const Tabs = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #EDF2F7;
    padding: 4px;
    margin-bottom: 28px;
`;

const Tab = styled.div`
    width: 50%;
    text-align: center;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
`;
const NoCursorTab = styled.div`
    width: 50%;
    text-align: center;
    padding: 6px 10px;
    border-radius: 4px;
`;

const FieldGroup = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 14px;
`;

const Field = styled.div`
    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }

    span {
        color: #E53E3E
    }

    input {
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        appearance: none;
        box-sizing: border-box;
        width: 100%;
        border: 0px;
        outline: none;

        padding: 8px 12px;

        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: #FFF;
        border: 1px solid #CBD5E0;
        border-radius: 6px;
        margin-bottom: 12px;
        margin-top: 6px;

        :focus {

        }
    }


    width: ${({fulled}) => fulled ? `100%` : '48%'};

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;
const Avatar = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-left: 8px;
`;
const Card = styled.div`
    border-radius: 12px;
    background: #F7FAFC;
    padding: 20px;
    margin-top: 20px;

    h5 {
        color: #171923;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
        margin-bottom: 16px;
    }
`;

const LaborCard = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px 0;
    cursor: pointer;

    &:hover {
        background-color: #EBF2FE !important;
        border-radius: 4px;
    }
`;

const LaborCardContent = styled.div`
    h5, h6 {
        margin: 0;
    }

    h5 {
        color: #4A5568;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px
    }

    h6 {
        color: #718096;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
`;
const EmptyLaborCard = styled.div`
    color: #4A5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    padding-top: 26px;
`;
const ImageList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    margin-top: 20px;

    h6 {
        width: fit-content;
    }
`;

const ImageCard = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;

    & > span {
        max-width: 120px;
        color: #718096;
        font-size: 13px;
        font-weight: normal;
        text-align: center;
        height: 120px;
        display: flex;
        align-items: center;
    }

    & > img {
        border-radius: 1px;
        cursor: pointer;
        margin: auto;
        max-width: 100%;
        //  min-height: 120px;
    }
`;

const ImageItem = styled.img`
    //width: 100%;
    //border-radius: 1px;
    //cursor: pointer;
    //margin: auto;
    //max-width: 120px;
    //min-height: 120px;
`;

const ImgHeader = styled.div`
    color: white;
    display: flex;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 20px;
    justify-content: center;

    align-items: center;
    font-size: 30px;
`;

export default Company;