import styled from '@emotion/styled';
import ReactSelect from 'react-select';

// 입력칸
export const InputBox = styled.div`
    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        //  line-height: 20px;
        margin: 0;

        span {
            color: #E53E3E;
        }
    }

    h5 {
        color: #171923;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        //margin: 8px;
        margin-left: 8px;
        margin-bottom: 12px;
        margin-top: 15px;

        span {
            color: #E53E3E;
        }
    }

    input {
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-sizing: border-box;
        width: 100%;
        border: 0px;
        outline: none;

        padding: 8px 12px;

        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: #FFF;
        border: 1px solid #CBD5E0;
        border-radius: 6px;
        margin-bottom: 8px;
        margin-top: 8px;

        :focus {

        }


    }

    textarea {
        width: 100%;
        min-height: 80px;
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        box-sizing: border-box;
        // margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    width: ${(props) => props.fulled ? `100%` : '48%'};
    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;

export const Select = styled(ReactSelect)`


    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
    border-radius: 6px;
    background: #FFF;
    margin-top: 7px;
    margin-bottom: 7px;

    .react-select__control {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;

        &:hover {
            border-color: #E2E8F0;
        }

        &:focus {
            border-color: transparent !important; // 선택 시 테두리 색상 투명하게
            box-shadow: none !important; // 선택 시 박스 그림자 제거
        }
    }


    div {

        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;

        &:hover {
            border-color: #E2E8F0;
        }
    }
`;

export const RightContent = styled.div`
    figure {
        margin: 0;
        display: flex;
        align-items: center;
        min-height: 34px;
    }

    h6, h5 {
        margin: 0;
    }

    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        width: 120px;
    }

    h5 {
        color: rgba(0, 0, 0, 0.92);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 12px 0;
    }
`;

export const PageGroup = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;

    ul {
        display: flex;
        padding: 0;
        list-style: none;
    }

    li a {
        //display: inline-block;
        cursor: pointer;
        color: #000;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
        //padding: 6px 12px;
        text-decoration: none;
        font-size: 12px;
    }

    li.active a {
        color: #fff;
        background-color: #3279F5 !important;
        border-radius: 4px;
    }

    li:hover:not(.active) a {
        background-color: #EBF2FE !important;
        border-radius: 4px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    figure {
        margin: 0;
        display: flex;
        align-items: baseline;
        // min-height: 34px;
    }


    h6, h5 {
        margin: 0;
    }

    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        min-width: 120px;
    }

    h5 {
        color: rgba(0, 0, 0, 0.92);
        font-size: 14px;
        font-style: normal;
        white-space: pre-wrap;
        font-weight: 500;
        line-height: 20px;
        word-break: break-all;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 12px 0;
    }
`;
