import axiosInstance from './axios-index';

const token = localStorage.getItem('admin_token');
// 결제 정보 > 등급 정보 데이터
//
// polling-data api
export const getPollingData = async () => {
    // console.log(findKey);
    return await axiosInstance.post(`/admin/get/polling-data`).then(response => {

        return response.data.result;
    }).catch(error => {
        console.log('error', error);
        throw error;
    });
};
export const planData = async (mst_cd_id) => {
    // console.log(findKey);
    return await axiosInstance.post(`/code/codeList`, {
        lang: 'ko',
        uuid: 'string',
        mst_cd_id: mst_cd_id,
    }).then(response => {

        return response.data.result;
    }).catch(error => {
        console.log('error', error);
        throw error;
    });
};


export const verifyUser = async () => {
    //console.log('verifyUser', token);
    return await axiosInstance.post(`/company/verifyUser`, {
            lang: 'ko',
            uuid: 'string',
            token
        }
    ).then((res
        ) => {
            return res.data.result;
        }
    ).catch(error => {
        console.log('error', error);
    });
};

// 결제 정보 > 결제 이력
export const planPaymentList = async (data) => {
    return await axiosInstance.post(`/admin/get/plan-payment-list`, {
            token,
            ...data

        }
    ).then((res
        ) => {
            return res.data.result;
        }
    ).catch(error => {
        console.log('error', error);
    });
};

// 결제 정보 > 세금계산서 정보(회사 정보)
export const companyInfoList = async () => {
    return await axiosInstance.post(`/company/info`, {
            lang: 'ko',
            uuid: 'string',
            token
        }
    ).then((res
        ) => {
            return res.data.result;
        }
    ).catch(error => {
        console.log('error', error);
    });
};
// 부트페이 결제 완료 처리
export const paymentConfirm = async ({receipt_id, price}) => {
    return await axiosInstance.post(`/payment/web/confirm`, {price: price, receipt_id: receipt_id}).then(res => {
        return res.data.result;
    }).catch(error => {

        throw error;
    });
};
// 부트페이 영수증 정보 조회
export const paymentReceipt = async (data) => {

    const {site_seq, receipt_id} = data;
    return await axiosInstance.post(`/payment/web/get/receipt-info`, {
        site_seq: site_seq,
        receipt_id: receipt_id

    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log('error', error);
        throw error;
    });
};
// 비회원 아이디 찾기
export const findCompanyID = async ({phone_numb, cert_numb, manager_nm}) => {
    return await axiosInstance.post(`/admin/get/company-id`, {
        phone_numb: phone_numb,
        cert_numb: cert_numb,
        manager_nm: manager_nm,
    }).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};

// 비회원 비밀번호 찾기
export const findCompanyPassword = async ({phone_numb, cert_numb, id, password}) => {

    return await axiosInstance.post(`/admin/update/company-password`, {
        phone_numb: phone_numb,
        cert_numb: cert_numb,
        id: id,
        password: password,
    }).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};
// 로그인
export const companySignIn = async ({corp_id, corp_pw}) => {
    return await axiosInstance.post(`/company/signIn`, {
        lang: 'ko',
        uuid: 'string',
        corp_id: corp_id,
        corp_pw: corp_pw
    }, {withCredentials: true}).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};

//admain > 현장 관리
export const adminSiteList = async (data) => {
    return await axiosInstance.post(`/admin/get/site-list`, {token, ...data}).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 관리 > 상세보기 > 수정하기 (현장 주소 리스트)
export const siteAddressList = async (site_seq) => {
    return await axiosInstance.post(`/admin/get/site-address-list`, {
        site_seq: site_seq
    }).then(response => {
        return response.data.result;
        // return response.data.result/;
    }).catch(error => {
        throw error;
    });
};

//현장관리 > 상세보기 > 수정하기 (현장 주소 수정)
export const siteAddressUpdate = async (data) => {
    return await axiosInstance.post(`/admin/update/site-address`, data).then(response => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

//현장관리 > 상세보기 > 수정하기 (현장 주소 생성)
export const siteAddressCreate = async (data) => {
    return await axiosInstance.post(`/admin/create/site-address`, data).then(response => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

//현장관리 > 상세보기 > 수정하기 (현장 주소 삭제)
export const siteAddressDelete = async ({site_seq, addr_seq}) => {
    return await axiosInstance.post(`/admin/delete/site-address`, {
        site_seq: site_seq,
        addr_seq: addr_seq
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

//근로자 표준 계약서 조회 api
export const getLaborContract = async (user_site_seq) => {
    return await axiosInstance.post(`/admin/get/labor-contract`, {
        user_site_seq: user_site_seq
    }).then((response) => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

//모든 현장 조회
export const getAllSearchSite = async (user_site_seq) => {
    return await axiosInstance.post(`/admin/searchSite`, {
        lang: 'ko',
        uuid: 'string',
        token,
        search_txt: ''
    }).then((response) => {

        return response.data.result;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

// 월별 4대보험 내역 조회 api
export const getInsuranceReport = async (data) => {
    // console.log('data', data);
    return await axiosInstance.post(`/admin/get/insurance-report`, data).then((response) => {

        return response.data.result;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

// admin > 전체 결제정보 조회
export const getAdminPaymentList = async (data) => {
    return await axiosInstance.post(`/admin/get/payment-list`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

// 신고자료 다운로드 > 4대보험 신고용 엑셀 파일 다운로드
export const downloadRepost = async (data) => {
    return await axiosInstance.post(`/admin/download/insurance-report`, {
        reportDate: data
    }, {
        responseType: 'blob' // 서버 응답을 blob으로 받기 위해 responseType
    }).then((response) => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

//현재 열려 있는 모든 팝업 세부 정보 제공 API
export const getAdminPopupData = async (data) => {
    return await axiosInstance.post(`/admin/pop-up/get/popup-data`).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 협력사 목록
export const getAdminSubPartner = async (data) => {
    const {site_seq, partner_seq = null} = data;

    return await axiosInstance.post(`/admin/get/sub-partner`, {
        site_seq: site_seq,
        partner_seq: partner_seq
    }).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 협력사 수정
export const modifySubPartner = async (data) => {
    return await axiosInstance.post(`/admin/update/sub-partner`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 협력사 등록
export const createSubPartner = async (data) => {
    return await axiosInstance.post(`/admin/create/sub-partner`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 협력사 기성 목록
export const getSubPartnerPayment = async (data) => {
    const {site_seq, partner_seq, work_dt} = data;
    return await axiosInstance.post(`/admin/get/sub-partner/payment`, {
        site_seq: site_seq,
        partner_seq: partner_seq,
        work_dt: work_dt
    }).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 협력사 목록 생성
export const createSubPartnerPayment = async (data) => {

    return await axiosInstance.post(`/admin/create/sub-partner/payment`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};


// 현장 협력사 목록 삭제
export const deleteSubPartnerPayment = async (data) => {
    return await axiosInstance.post(`/admin/delete/sub-partner/payment`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 현장 협력사 목록 수정
export const modifySubPartnerPayment = async (data) => {
    return await axiosInstance.post(`/admin/update/sub-partner/payment`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};
// 근로자 공수 수정 및 생성(외주 관리 용)
export const modifyCommuteRecord = async (data) => {

    return await axiosInstance.post(`/admin/update/commute-record`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 회사 현장 관리자 정보
export const getCompanyManagerUserInfo = async (data) => {
    return await axiosInstance.post(`/company/get/manager-user-info`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};
// 현장 협력사 목록 삭제
export const deleteLaborAttendanceClosing = async (data) => {
    return await axiosInstance.post(`/admin/delete/labor-attendance-closing`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 회사 정보> 결재양식 설정(부서별 직급 지정(생성))
export const createApprovalLine = async (data) => {
    return await axiosInstance.post(`/admin/create/approval-line`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 회사 정보> 결재양식 설정(부서별 직급 지정 정보)
export const getApprovalLine = async (data) => {
    return await axiosInstance.post(`/admin/get/approval-line`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 회사 정보> 결재양식 설정(부서별 직급 지정(수정))
export const modifyApprovalLine = async (data) => {
    return await axiosInstance.post(`/admin/update/approval-line`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};
// 결제 정보 > 정기 결제 부트페이 정기 결제 여부 확인 API(WEB)
export const getAutoBilling = async (data) => {
    return await axiosInstance.post(`/payment/web/get/auto-billing`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 결제 정보 > 정기 결제 부트페이 정기 결제 용 빌링 키 발급 및 저장
export const modifyAutoBillingKey = async (data) => {
    return await axiosInstance.post(`/payment/web/update/billing-key`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 결제 정보 > 정기 결제 부트페이 정기 결제 용 빌링 키 관련 정보 조회
export const getAutoBillingKey = async (corp_seq) => {
    return await axiosInstance.post(`/payment/web/get/billing-key`, {corp_seq: corp_seq}).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 결제 정보 > 정기 결제 부트페이 정기 결제 용 빌링 키 삭제
export const deleteAutoBillingKey = async (corp_seq) => {
    return await axiosInstance.post(`/payment/web/delete/billing-key`, {corp_seq: corp_seq}).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};