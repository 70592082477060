import styled from '@emotion/styled';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Header} from '../../compontents/Header';
import ReactSelect from 'react-select';
import Modal from '../../compontents/Modal';
import MonthCalendar from '../../compontents/MonthCalendar';
import TransferRequestPagePrint from '../../compontents/print/TransferRequestPrint';
import {useReactToPrint} from 'react-to-print';
import {HOST_URL} from '../../bin/env';
import dayjs from 'dayjs';
import DownLoadExcel from '../../library/handle-excel';
import SiteContainers from '../../compontents/SiteContainer';
import axiosInstance from '../../library/axios-index';
import AxiosNavigate from '../../library/axios-navigate';
import {BlockScrolling, resetPageStyle, setPageStyle} from '../../bin/common';
import EmptyContainer from '../../compontents/EmptyContainer';

function LaborTransferRequest() {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => {
            const style = `@page { size: A4 portrait !important; }`;
            setPageStyle(style);
            return style;
        },
        onAfterPrint: resetPageStyle,
    });

    const [opendExport, setOpendExport] = useState(false);

    const [sites, setSites] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [report, setReport] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedSite, setSelectedSite] = useState(0);

    BlockScrolling(opendExport);
    const handleAllSites = useCallback(async () => {

        const token = localStorage.getItem('admin_token');

        const {data: {result}} = await axiosInstance.post(`${HOST_URL}/admin/searchSite`, {
            lang: 'ko',
            uuid: 'string',
            token,
            search_txt: ''
        });

        setSites(result);
        setSiteList(result);
        setSelectedSite(0);
    }, []);

    // 현장 검색
    const handleSearchSite = (value) => {
        const list = siteList.filter((e) => e.site_nm.toLowerCase().replaceAll(' ', '').includes(value.toLowerCase().replaceAll(' ', '')));
        setSites(list);
    };

    const handleSelectedSite = (e) => {
        setSelectedSite(e);
    };

    const loadReport = async () => {

        const token = localStorage.getItem('admin_token');

        const {data: {result}} = await axiosInstance.post(`/admin/laborTransferRequestList`, {
            lang: 'ko',
            uuid: 'stirng',
            token,
            site_seq: selectedSite,
            work_dt: selectedDate,
            // page,
            // limit,
        });

        if (!Object.keys(result).length) {
            alert('급여 이체요청서가 없습니다');
            return;
        }

        setReport(result);
        //setTotalCount(result.totalCount)

    };

    const handleExcel = () => {
        if (report.users.length < 2) {
            alert('급여이체요청서가 없습니다.');
            return;
        }


        DownLoadExcel({txt: '급여이체요청서', date: dayjs(selectedDate).format('YYYY-MM'), site: report.site_nm});
    };

    const handleOpenPrintModal = useCallback(() => {
        setOpendExport(true);

    }, []);

    useEffect(() => {

        (async () => {

            if (!selectedDate || selectedSite === 0) return;

            await loadReport();

        })();
    }, [selectedDate, selectedSite]);

    useEffect(() => {
        (async () => {
            handleAllSites();
        })();

    }, []);

    return (

        <AxiosNavigate>
            <div>
                <Header>급여 이체요청서</Header>
                <Content>
                    <Info>
                        <CalendarContainer style={{paddingLeft: '16px'}}>
                            <MonthCalendar onChangeDate={
                                (e) => {
                                    setSelectedDate(e);
                                }
                            }/>
                        </CalendarContainer>

                        <SiteContainers
                            data={{
                                sites,
                                handleAllSites,
                                handleSearchSite,
                                handleSelectedSite
                            }}
                        />
                    </Info>
                    {selectedSite !== 0 && report?.users?.length > 1
                        ?
                        <Board>

                            <Panel>
                                <div>
                                    <BoardHeader>
                                        <h2>{report.transferMonth}월 급여 이체 요청서</h2>
                                        <h5>
                                            <strong>현장명</strong> <span
                                            style={{color: '#718096'}}>{report.site_nm}</span>
                                        </h5>
                                    </BoardHeader>
                                </div>
                                <div>
                                    <ButtonGroup>
                                        <Button onClick={handleExcel}><Icon
                                            src="/assets/icons/excel.svg"/> 엑셀파일</Button>
                                        <Button onClick={handleOpenPrintModal}><Icon
                                            src="/assets/icons/printer.svg"/> 출력하기</Button>
                                    </ButtonGroup>
                                </div>
                            </Panel>

                            <TableContainer>
                                <TableWrapper>
                                    <Table id={'report-table'}>
                                        <thead>

                                        <tr>
                                            <th width={'50px'}>구분</th>
                                            <th>은행코드</th>
                                            <th>계좌번호</th>
                                            <th>급액</th>
                                            <th>입지내역</th>
                                            <th>계좌명</th>
                                            <th>계좌명의관계</th>
                                            <th>사원명</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {report.users.map((e, i) => {
                                            if (report.users.length - 1 === i) {
                                                return <tr key={i}>
                                                    <th colSpan={3}>합계</th>
                                                    <th className={'table-right'}>{e.salary_amt.toLocaleString()}원</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>;
                                            } else {
                                                return <tr key={i}>
                                                    <td style={{textAlign: 'center'}}>
                                                        <div style={{padding: '8px 0'}}>{i + 1}</div>
                                                    </td>
                                                    <td>{e.bank_nm}</td>
                                                    <td>{e.bank_acct}</td>
                                                    <td className={'table-right'}>{e.salary_amt.toLocaleString()}원</td>
                                                    <td style={{textAlign: 'center'}}>{e.debit_credit_type}</td>
                                                    <td style={{textAlign: 'center'}}>{e.bank_user_nm}</td>
                                                    <td style={{textAlign: 'center'}}>{e.bank_user_rel}</td>
                                                    <td style={{textAlign: 'center'}}>{e.user_nm}</td>
                                                </tr>;
                                            }
                                        })}
                                        </tbody>
                                    </Table>
                                </TableWrapper>
                            </TableContainer>


                        </Board>
                        :
                        <EmptyContainer selected={selectedSite === 0} falseText={'급여 이체요청서가'}> </EmptyContainer>
                    }

                </Content>

                <Modal
                    opend={opendExport}
                    closeModal={() => setOpendExport(false)}
                    header={'출력 미리보기'}
                    okText={'출력'}
                    handleOk={() => {
                        handlePrint();
                    }}
                    widthCheck={'55%'}
                >
                    <TransferRequestPagePrint ref={componentRef} data={{report: report, date: selectedDate}}
                                              siteSeq={selectedSite}/>
                </Modal>
            </div>
        </AxiosNavigate>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }
`;

const Info = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        flex-direction: column;
    }
`;

const CalendarContainer = styled.div`
    width: 100%;
    padding: 20px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    @media only screen and (min-width: 768px) {
        width: 30%;
    }

    @media only screen and (min-width: 992px) {
        width: 280px;
    }
`;


const Button = styled.div`
    display: flex;
    height: 32px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #fff;
    color: #4A5568;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
`;

const SiteSearch = styled.div`
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 12px;
    background: #F7FAFC;
    border-radius: 8px;
`;

const SiteSearchIcon = styled.img`
    padding-left: 12px;
`;

const SiteSearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    background: #F7FAFC;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const EmptyReport = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
        margin: 0;
        padding: 0;
        color: #A0AEC0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 992px) {
        min-height: 333px;
        margin-top: 24px;
    }
`;

const EmptyIcon = styled.img`
`;

const Board = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    margin-top: 24px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;


const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    background: #fff;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;

const BoardButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;

    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

    margin-top: 5px;

`;

const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;

const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #e2e8f0;
        text-align: center;
    }

    tr:last-child td {
        border-bottom: none;
    }

    td {
        background: #fff;
    }

    tbody tr:last-of-type th {
        border-bottom: none;
    }
`;

const PageGroup = styled.div`
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        padding: 0;
        list-style: none;
    }

    li a {
        display: inline-block;
        cursor: pointer;
        color: #000;
        padding: 6px 12px;
        text-decoration: none;
        font-size: 12px;
    }

    li.active a {
        color: #fff;
        background-color: #3279F5 !important;
        border-radius: 4px;
    }

    li:hover:not(.active) a {
        background-color: #EBF2FE !important;
        border-radius: 4px;
    }
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;

const BoardHeader = styled.div`
    margin-bottom: 14px;
    margin-top: 28px;

    h2, h5 {
        margin: 0;
    }

    h5 {
        margin-top: 10px;
    }
`;

const Label = styled.span`
    display: inline-block;
    padding: 4px 12px;
    margin-right: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 100px;
    background: #EDF2F7;
    color: #4A5568;

    strong {
        color: #1A202C;
    }
`;

export default LaborTransferRequest;