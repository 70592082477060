import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, {forwardRef} from 'react';
import PrintSignComponent from '../PrintSignComponent';

const TransferRequestPagePrint = forwardRef((props, ref) => {

    const report = props.data.report;
    const date = props.data.date;
    const site_seq = props.siteSeq;

    return (
        <Container ref={ref}>

            <Header>
                <div>
                    <Title>{dayjs(date).format('YYYY년 MM월')}분 급여 이체요청서</Title>
                    <HeaderGroup>
                        <Badge>
                            <Label>현장명</Label>
                            <Text>{report.site_nm}</Text>
                        </Badge>
                    </HeaderGroup>
                </div>

                <PrintSignComponent seq={site_seq}>

                </PrintSignComponent>
            </Header>
            <TableContainer className={'table-wrapper'}>
                <TableWrapper>
                    <Table>
                        <thead>

                        <tr>
                            <th>구분</th>
                            <th>은행코드</th>
                            <th>계좌번호</th>
                            <th>급액</th>
                            <th>입지내역</th>
                            <th>계좌명</th>
                            <th>계좌명의관계</th>
                            <th>사원명</th>
                        </tr>
                        </thead>
                        <tbody>

                        {report.users.map((e, i) => {
                            if (report.users.length - 1 === i) {
                                return <tr key={i}>
                                    <th colSpan={3}>합계</th>
                                    <th className={'table-right'}>{e.salary_amt.toLocaleString()}원</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>;
                            } else {
                                return <tr key={i}>
                                    <td>
                                        {i + 1}
                                    </td>
                                    <td style={{textAlign: 'left'}}>{e.bank_nm}</td>
                                    <td style={{textAlign: 'left'}}>{e.bank_acct}</td>
                                    <td className={'table-right'}>{e.salary_amt.toLocaleString()}원</td>
                                    <td>{e.debit_credit_type}</td>
                                    <td>{e.bank_user_nm}</td>
                                    <td>{e.bank_user_rel}</td>
                                    <td>{e.user_nm}</td>
                                </tr>;
                            }
                        })}
                        </tbody>
                    </Table>
                </TableWrapper>
            </TableContainer>

        </Container>
    );
});

const Container = styled.div`
    //border: 1px solid #E2E8F0;
    border: 1px solid #FFFFFF;
    background: #FFF;
    padding: 20px 24px;

    @page {
        size: A4 portrait !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    @media print {
        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }

        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #718096;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    margin-right: 4px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

const TableContainer = styled.div`
`;

const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
    border-radius: 6px;
    border: 1px solid #000;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    thead {
        height: 20px;
    }

    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        //border-bottom: 1px solid #E2E8F0;
        //border-right: 1px solid #E2E8F0;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tbody tr:last-child th {
        border-bottom: none;
    }

    tfoot th {
        border-bottom: none;
        font-weight: bold;
        text-align: right;
    }

    tr {
        height: 20px;
    }
`;

export default TransferRequestPagePrint;