import styled from '@emotion/styled';
import React, {forwardRef} from 'react';
import PrintSignComponent from '../PrintSignComponent';

const tdCustomStyle = {textAlign: 'right', paddingRight: '3px'};
const attendDayStyle = {width: '15px'};

const PaymentStatementPagePrint = forwardRef((props, ref) => {

    const list = props.data.attendList;
    const belong = props.belong;
    const site_seq = props.siteSeq;

    return (
        <Container ref={ref}>
            <Header>
                <div>
                    <Title>{props.data.period.substring(0, 9)}분 노무비지급명세서</Title>
                    <HeaderGroup>
                        <Badge>
                            <Label>기간</Label>
                            <Text>{props.data.period} </Text>
                        </Badge>

                        <Badge>
                            <Label>공사명</Label>
                            <Text>{props.data.const_nm}</Text>
                        </Badge>

                        <Badge>
                            <Label>현장명</Label>
                            <Text>{props.data.site_nm}</Text>
                        </Badge>

                        {
                            props?.belong?.label ?
                                <Badge>
                                    <Label>소속</Label>
                                    <Text>{props?.belong?.label}</Text>
                                </Badge> : <></>
                        }
                    </HeaderGroup>
                </div>

                <PrintSignComponent seq={site_seq}>

                </PrintSignComponent>
            </Header>

            <TableContainer className={'table-wrapper'}>
                <TableWrapper>
                    <Table>
                        <thead>
                        <tr>
                            <th rowSpan={2} style={{
                                width: '30px',
                                borderBottomWidth: '1px',
                                borderBottomColor: '#000'
                            }}>일번
                            </th>
                            <th style={{width: '60px', fontSize: '12px'}} rowSpan={3}>성명</th>
                            <th style={{width: '250px'}} rowSpan={2}>주소</th>
                            <th colSpan={16}>출 역 사 항</th>
                            <th style={{width: '170px', fontSize: '9px'}} colSpan={3} rowSpan={2}>주차/년월차<br/>/추가</th>
                            <th style={{width: '70px'}} rowSpan={2}>단가</th>
                            <th style={{width: '70px'}} rowSpan={2}>소득세</th>
                            <th style={{width: '70px'}} rowSpan={2}>고용보험</th>
                            <th style={{width: '70px'}} rowSpan={2}>국민연금</th>
                            <th style={{width: '70px'}} rowSpan={2}>기타/노조</th>
                            <th style={{width: '70px'}} rowSpan={2}>식대</th>
                        </tr>
                        <tr>
                            <th style={attendDayStyle}>1</th>
                            <th style={attendDayStyle}>2</th>
                            <th style={attendDayStyle}>3</th>
                            <th style={attendDayStyle}>4</th>
                            <th style={attendDayStyle}>5</th>
                            <th style={attendDayStyle}>6</th>
                            <th style={attendDayStyle}>7</th>
                            <th style={attendDayStyle}>8</th>
                            <th style={attendDayStyle}>9</th>
                            <th style={attendDayStyle}>10</th>
                            <th style={attendDayStyle}>11</th>
                            <th style={attendDayStyle}>12</th>
                            <th style={attendDayStyle}>13</th>
                            <th style={attendDayStyle}>14</th>
                            <th style={attendDayStyle}>15</th>
                            <th style={attendDayStyle} className="right">16</th>
                        </tr>
                        <tr>
                            <th>직종</th>
                            <th>주민등록번호/외국인관리번호</th>
                            <th style={attendDayStyle}>17</th>
                            <th style={attendDayStyle}>18</th>
                            <th style={attendDayStyle}>19</th>
                            <th style={attendDayStyle}>20</th>
                            <th style={attendDayStyle}>21</th>
                            <th style={attendDayStyle}>22</th>
                            <th style={attendDayStyle}>23</th>
                            <th style={attendDayStyle}>24</th>
                            <th style={attendDayStyle}>25</th>
                            <th style={attendDayStyle}>26</th>
                            <th style={attendDayStyle}>27</th>
                            <th style={attendDayStyle}>28</th>
                            <th style={attendDayStyle}>29</th>
                            <th style={attendDayStyle}>30</th>
                            <th style={attendDayStyle}>31</th>
                            <th style={attendDayStyle}></th>
                            <th style={{width: '100px', fontSize: '9px'}} colSpan={3}>총일수/공수</th>
                            <th>노무비총액</th>
                            <th>지방소득세</th>
                            <th>건강보험</th>
                            <th>요양보험</th>
                            <th>공제계</th>
                            <th>실지급액</th>
                        </tr>

                        </thead>
                        <tbody>
                        {list.length && list.map((e, i) => {
                            if (i === list.length - 1) {
                                return (<React.Fragment key={i}>
                                    <tr>
                                        <th colSpan={19} rowSpan={2}>총계</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.day_amt.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax1.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax2.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax3.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax4.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax5.toLocaleString()}</th>
                                    </tr>
                                    <tr>
                                        <th className={'table-right'} style={tdCustomStyle}>{e.attend_cnt}</th>
                                        <th className={'table-right'} style={tdCustomStyle} colSpan={2}>{e.man_day}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.salary_amt.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax6.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax7.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax8.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.tax9.toLocaleString()}</th>
                                        <th className={'table-right'}
                                            style={tdCustomStyle}>{e.real_salary_amt.toLocaleString()}</th>
                                    </tr>
                                </React.Fragment>);
                            } else {
                                return (<React.Fragment key={i}>
                                    <tr style={{borderBottom: '1px dashed #000000'}}>
                                        <td rowSpan={1}>{e.seq}</td>
                                        <td style={{
                                            width: '50px', fontSize: '12px',
                                            whiteSpace: 'break-spaces'
                                        }}
                                            rowSpan={2}>{e.user_nm}</td>
                                        <td style={{
                                            height: '20px',
                                            wordWrap: 'break-word',
                                            textAlign: 'left',
                                            fontSize: '9px',
                                            whiteSpace: 'break-spaces'
                                        }} rowSpan={1}>{e.addr}</td>
                                        <td>{e.monthAttendList[0] === 10 ? '' : e.monthAttendList[0]}</td>
                                        <td>{e.monthAttendList[1] === 10 ? '' : e.monthAttendList[1]}</td>
                                        <td>{e.monthAttendList[2] === 10 ? '' : e.monthAttendList[2]}</td>
                                        <td>{e.monthAttendList[3] === 10 ? '' : e.monthAttendList[3]}</td>
                                        <td>{e.monthAttendList[4] === 10 ? '' : e.monthAttendList[4]}</td>
                                        <td>{e.monthAttendList[5] === 10 ? '' : e.monthAttendList[5]}</td>
                                        <td>{e.monthAttendList[6] === 10 ? '' : e.monthAttendList[6]}</td>
                                        <td>{e.monthAttendList[7] === 10 ? '' : e.monthAttendList[7]}</td>
                                        <td>{e.monthAttendList[8] === 10 ? '' : e.monthAttendList[8]}</td>
                                        <td>{e.monthAttendList[9] === 10 ? '' : e.monthAttendList[9]}</td>
                                        <td>{e.monthAttendList[10] === 10 ? '' : e.monthAttendList[10]}</td>
                                        <td>{e.monthAttendList[11] === 10 ? '' : e.monthAttendList[11]}</td>
                                        <td>{e.monthAttendList[12] === 10 ? '' : e.monthAttendList[12]}</td>
                                        <td>{e.monthAttendList[13] === 10 ? '' : e.monthAttendList[13]}</td>
                                        <td>{e.monthAttendList[14] === 10 ? '' : e.monthAttendList[14]}</td>
                                        <td>{e.monthAttendList[15] === 10 ? '' : e.monthAttendList[15]}</td>

                                        {/* 주차/년월차/추가 */}
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.day_amt.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax1.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax2.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax3.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax4.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax5.toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>{e.job_position}</td>
                                        <td style={{height: '20px', textAlign: 'left', fontSize: '9px'}}>{e.id_num}</td>
                                        <td>{e.monthAttendList[16] === 10 ? '' : e.monthAttendList[16]}</td>
                                        <td>{e.monthAttendList[17] === 10 ? '' : e.monthAttendList[17]}</td>
                                        <td>{e.monthAttendList[18] === 10 ? '' : e.monthAttendList[18]}</td>
                                        <td>{e.monthAttendList[19] === 10 ? '' : e.monthAttendList[19]}</td>
                                        <td>{e.monthAttendList[20] === 10 ? '' : e.monthAttendList[20]}</td>
                                        <td>{e.monthAttendList[21] === 10 ? '' : e.monthAttendList[21]}</td>
                                        <td>{e.monthAttendList[22] === 10 ? '' : e.monthAttendList[22]}</td>
                                        <td>{e.monthAttendList[23] === 10 ? '' : e.monthAttendList[23]}</td>
                                        <td>{e.monthAttendList[24] === 10 ? '' : e.monthAttendList[24]}</td>
                                        <td>{e.monthAttendList[25] === 10 ? '' : e.monthAttendList[25]}</td>
                                        <td>{e.monthAttendList[26] === 10 ? '' : e.monthAttendList[26]}</td>
                                        <td>{e.monthAttendList[27] === 10 ? '' : e.monthAttendList[27]}</td>
                                        <td>{e.monthAttendList[28] === 10 ? '' : e.monthAttendList[28]}</td>
                                        <td>{e.monthAttendList[29] === 10 ? '' : e.monthAttendList[29]}</td>
                                        <td>{e.monthAttendList[30] === 10 ? '' : e.monthAttendList[30]}</td>
                                        <td></td>
                                        <td style={{...tdCustomStyle, width: '10px'}} colSpan={1}> {e.attend_cnt}</td>
                                        <td style={{...tdCustomStyle, width: '70px'}} colSpan={2}>{e.man_day}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.salary_amt.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax6.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax7.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax8.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.tax9.toLocaleString()}</td>
                                        <td className={'table-right'}
                                            style={tdCustomStyle}>{e.real_salary_amt.toLocaleString()}</td>
                                    </tr>
                                </React.Fragment>);
                            }
                        })}
                        </tbody>
                    </Table>
                </TableWrapper>
            </TableContainer>

        </Container>
    );
});

const Container = styled.div`
    border: 1px solid #FFFFFF;
    background: #FFF;
    padding: 20px 24px;


    @page {
        size: A4 landscape !important;
        margin-top: 20px;
        margin-bottom: 25px;
        //size: landscape; // 가로 방향으로 설정
    }
    @media print {
        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }

        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
            box-sizing: border-box;
        }

    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 6px 6px;
    //align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;
    align-items: center;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 8px;
    text-wrap: nowrap;
    margin-right: 8px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;

    font-style: normal;
    font-weight: 450;
    //line-height: 8px;

`;

const TableContainer = styled.div`
`;

const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
    border-radius: 6px;
    border: 1px solid #000;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    //table-layout: fixed;

    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th.right {
        border-right: 1px solid #000;
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tr:last-child td {
        //border-bottom: none;
    }

    tfoot th {
        border-bottom: none;
        font-weight: bold;
        text-align: right;
    }

    tr {
        height: 20px;
    }
`;

export default PaymentStatementPagePrint;