import styled from '@emotion/styled';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Header} from '../../compontents/Header';
import ReactSelect from 'react-select';
import Modal from '../../compontents/Modal';
import MonthCalendar from '../../compontents/MonthCalendar';
import PaymentStatementPagePrint from '../../compontents/print/PaymentStatementPagePrint';
import {useReactToPrint} from 'react-to-print';
import {HOST_URL} from '../../bin/env';
import dayjs from 'dayjs';
import DownLoadExcel from '../../library/handle-excel';
import SiteContainers from '../../compontents/SiteContainer';
import axiosInstance from '../../library/axios-index';
import AxiosNavigate from '../../library/axios-navigate';
import {BlockScrolling, parse, resetPageStyle, setPageStyle} from '../../bin/common';
import {ClickAwayListener} from '@mui/material';
import EmptyContainer from '../../compontents/EmptyContainer';
import SelectedFilter from '../../compontents/SelectedFilter';
import Loading from '../../compontents/Loading';
import moment from 'moment';
import SearchBox from '../../compontents/Search';

const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },
    })
};

function LaborPaymentStatement() {

    const componentRef = useRef();
    const [loading, setLoading] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => {
            const style = `@page { size: A4 landscape !important; }`;
            setPageStyle(style);
            return style;
        },
        onAfterPrint: resetPageStyle,
    });
    const [openReportInfo, setOpenReportInfo] = useState(null);
    const [opendExport, setOpendExport] = useState(false);

    const [sites, setSites] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [report, setReport] = useState(undefined);
    const [reportEmpty, setReportEmpty] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-01'));
    const [selectedSite, setSelectedSite] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [belongFilter, setBelongFilter] = useState({});
    const [belongFilterOption, setBelongFilterOption] = useState([]);
    const belongRef = useRef(null);


    const handleAllSites = useCallback(async () => {
        const token = localStorage.getItem('admin_token');

        const {
            data: {result},
        } = await axiosInstance.post(`${HOST_URL}/admin/searchSite`, {
            lang: 'ko',
            uuid: 'string',
            token,
            search_txt: '',
        });

        setSites(result);
        setSiteList(result);
        setReport(undefined);
        setSelectedSite(0);
        setOpenReportInfo(null);
    }, []);

    BlockScrolling(opendExport);
    // 현장 검색
    const handleSearchSite = (value) => {
        const list = siteList.filter((e) => e.site_nm.toLowerCase().replaceAll(' ', '').includes(value.toLowerCase().replaceAll(' ', '')));
        setSites(list);
    };

    const handleSelectedSite = (e) => {
        setBelongFilter({});
        setBelongFilterOption([]);
        setSearchTxt('');
        setSearchValue('');
        setSelectedSite(e);
        if (belongRef.current) {
            belongRef.current.clearValue();
        }
    };

    const loadReport = useCallback(async () => {


        const token = localStorage.getItem('admin_token');

        const request = {
            lang: 'ko',
            uuid: 'stirng',
            token,
            site_seq: selectedSite,
            work_dt: selectedDate,
            userNm: searchTxt
        };
        if (belongFilter) {
            if (Object.keys(belongFilter).length > 0) {
                request['belongFilter'] = belongFilter.value;
                request['belongType'] = belongFilter.type;
            }
        }

        const {data: {result}} = await axiosInstance.post(
            `${HOST_URL}/admin/laborPaymentStatementList`,
            request
        );

        const reportArray = Array(result.attendList.length).fill(false);
        setOpenReportInfo(reportArray);
        setTimeout(() => {
            setLoading(false);
        }, 400);

        setReport(result);
        if (result.attendList.length <= 1) {
            // alert('노무비지급명세서가 없습니다')
            // setReport(undefined);
            setBelongFilterOption([]);
            setReportEmpty(true);
            if (belongRef.current) {
                belongRef.current.clearValue();
            }
            return;
        }
        setReportEmpty(false);

        if (belongFilterOption.length == 0) {
            result.belongFilter.unshift({label: '전체 소속', value: ''});
            setBelongFilterOption(result.belongFilter);
        }

    }, [selectedSite, selectedDate, belongFilter, searchTxt]);
    useEffect(() => {
        // 이번달 기준으로 이잔 월만 로딩 작용
        if (!selectedSite) return;
        if (moment(new Date()).format('YYYY-MM-DD') >= selectedDate) {
            setLoading(true);
        }
    }, [selectedSite, selectedDate]);
    const handleExcel = () => {
        if (report.attendList.length < 2) {
            setReportEmpty(true);
            // alert('노무비지급명세서가 없습니다.');
            return;
        }

        DownLoadExcel({txt: '노무비지급명세서', date: dayjs(selectedDate).format('YYYY-MM'), site: report.site_nm});
    };

    const handleOpenPrintModal = useCallback(() => {
        setOpendExport(true);
    }, []);

    useEffect(() => {

        (async () => {
            if (!selectedSite) return;

            loadReport();

        })();

    }, [selectedDate, selectedSite, belongFilter, searchTxt]);

    useEffect(() => {
        (async () => {
            //setLoading(true);
            handleAllSites();
        })();

    }, []);

    const onClickSendPayStub = useCallback(async () => {

        const request = {
            site_seq: selectedSite,
            payStubDate: dayjs(selectedDate).format('YYYY-MM'),
        };
        try {
            const {data: {result}} = await axiosInstance.post(
                `${HOST_URL}/admin/sendPayStub`,
                request
            );
            if (result.done) {
                alert('정상적으로 발급 되었습니다.');
            }

        } catch (e) {
            alert(e.response.data.message);
        }
    }, [selectedSite, selectedDate]);

    const handleOpenInfo = useCallback((info, index) => {
        const reportArray = Array(report?.attendList?.length).fill(false);
        if (!openReportInfo[index]) {
            reportArray[index] = true;
        }
        setOpenReportInfo(reportArray);
    }, [report, openReportInfo]);

    const onClickAway = () => {

    };
    return (
        <AxiosNavigate>
            <div>
                <Header>노무비지급명세서</Header>
                <Content>
                    <Info>
                        <CalendarContainer style={{paddingLeft: '16px'}}>
                            <MonthCalendar onChangeDate={(date) => {
                                setSelectedDate(dayjs(date).format('YYYY-MM-01'));
                                setBelongFilter({});
                                setSearchTxt('');
                                setSearchValue('');
                                setBelongFilterOption([]);
                                if (belongRef.current) {
                                    belongRef.current.clearValue();
                                }
                            }}/>
                        </CalendarContainer>

                        <SiteContainers
                            data={{
                                sites,
                                handleAllSites,
                                handleSearchSite,
                                handleSelectedSite
                            }}
                        />
                    </Info>
                    {
                        loading ? <Loading></Loading> :
                            selectedSite !== 0
                                ?
                                <Board>
                                    <Panel>
                                        <BoardHeader style={{padding: 0}}>
                                            <h2>
                                                {report?.site_nm}
                                            </h2>
                                        </BoardHeader>

                                        <div>
                                            <ButtonGroup>
                                                <SearchBox placeholder="직원 검색"
                                                           onKeyDown={(e) => {
                                                               if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                                                   if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
                                                                       alert('직원을 입력해 주세요');
                                                                       setSearchValue(e.target.value.trim());
                                                                       return;
                                                                   }
                                                                   setSearchValue(e.target.value.trim());
                                                                   setSearchTxt(e.target.value);
                                                               }
                                                           }} value={searchValue} onChange={(e) => {
                                                    setSearchValue(e.target.value);
                                                }}
                                                           style={{
                                                               background: '#fff',
                                                               height: '37px'
                                                           }}></SearchBox>
                                                <Button onClick={handleExcel}><Icon
                                                    src="/assets/icons/excel.svg"/> 엑셀파일</Button>
                                                <Button onClick={onClickSendPayStub}><Icon
                                                    src="/assets/icons/file-05.svg"/>급여명세서
                                                    전송</Button>
                                                <Button onClick={handleOpenPrintModal}><Icon
                                                    src="/assets/icons/printer.svg"/> 출력하기</Button>
                                                <SelectedFilter style={{
                                                    height: '38px',
                                                    width: '130px',
                                                    border: '1px solid #E2E8F0'
                                                }}
                                                                placeholder={'전체 소속'}
                                                                iconName={'menu-02.svg'}
                                                                options={belongFilterOption}
                                                                onChange={(e) => {
                                                                    setBelongFilter(e);
                                                                }}>

                                                </SelectedFilter>
                                            </ButtonGroup>
                                        </div>
                                    </Panel>
                                    <BoardHeader>
                                        {/*<h2>{selectedSiteNm}</h2>*/}
                                        <h5>
                                            <strong style={{marginRight: '2px'}}>기간 </strong>
                                            <span> {parse(report?.period)} </span>
                                        </h5>
                                    </BoardHeader>

                                    {
                                        report?.attendList[0]?.user_site_seq !== 0 ?
                                            <ClickAwayListener onClickAway={onClickAway}>
                                                <TableContainer>
                                                    <TableWrapper>
                                                        <Table id={'report-table'}>
                                                            <thead>
                                                            <tr>
                                                                <th rowSpan={2}>구분</th>
                                                                <th rowSpan={3}>이름</th>
                                                                <th rowSpan={2}>주소</th>
                                                                <th colSpan={16}>출역사항</th>
                                                                <th colSpan={3} rowSpan={2}>주차/년월차<br/>/추가</th>
                                                                <th rowSpan={2}>단가</th>
                                                                <th rowSpan={2}>소득세</th>
                                                                <th rowSpan={2}>고용보험</th>
                                                                <th rowSpan={2}>국민연금</th>
                                                                <th rowSpan={2}>기타/노조</th>
                                                                <th rowSpan={2}>식대</th>
                                                            </tr>
                                                            <tr>
                                                                <th>1</th>
                                                                <th>2</th>
                                                                <th>3</th>
                                                                <th>4</th>
                                                                <th>5</th>
                                                                <th>6</th>
                                                                <th>7</th>
                                                                <th>8</th>
                                                                <th>9</th>
                                                                <th>10</th>
                                                                <th>11</th>
                                                                <th>12</th>
                                                                <th>13</th>
                                                                <th>14</th>
                                                                <th>15</th>
                                                                <th className="border">16</th>
                                                            </tr>
                                                            <tr>
                                                                <th>직종</th>
                                                                <th>주민등록번호</th>
                                                                <th>17</th>
                                                                <th>18</th>
                                                                <th>19</th>
                                                                <th>20</th>
                                                                <th>21</th>
                                                                <th>22</th>
                                                                <th>23</th>
                                                                <th>24</th>
                                                                <th>25</th>
                                                                <th>26</th>
                                                                <th>27</th>
                                                                <th>28</th>
                                                                <th>29</th>
                                                                <th>30</th>
                                                                <th>31</th>
                                                                <th></th>
                                                                <th colSpan={3}>총일수/공수</th>
                                                                <th>노무비총액</th>
                                                                <th>지방소득세</th>
                                                                <th>건강보험</th>
                                                                <th>요양보험</th>
                                                                <th>공제계</th>
                                                                <th>실지급액</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {report?.attendList?.length && report?.attendList?.map((e, i) => {
                                                                if (i === report.attendList.length - 1) {
                                                                    return (<React.Fragment key={i}>
                                                                        <tr>
                                                                            <th colSpan={19} rowSpan={2}>총계</th>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th className={'table-right'}>{e.day_amt.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax1.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax2.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax3.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax4.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax5.toLocaleString()}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>{e.attend_cnt}</th>
                                                                            <th colSpan={2}>{e.man_day}</th>
                                                                            <th className={'table-right'}>{e.salary_amt.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax6.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax7.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax8.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.tax9.toLocaleString()}</th>
                                                                            <th className={'table-right'}>{e.real_salary_amt.toLocaleString()}</th>
                                                                        </tr>
                                                                    </React.Fragment>);
                                                                } else {
                                                                    return (<React.Fragment key={i}>
                                                                        <tr>
                                                                            <td rowSpan={1}>{e.seq}</td>
                                                                            <td rowSpan={2} style={{cursor: 'pointer'}}
                                                                                onClick={(event) => handleOpenInfo(e, i)}>{e.user_nm}</td>
                                                                            <td rowSpan={1} style={{
                                                                                maxWidth: '200px',
                                                                                minWidth: '200px',
                                                                                textAlign: 'left',
                                                                                wordWrap: 'break-word',
                                                                                overflowWrap: 'break-word',
                                                                                wordBreak: 'break-all',
                                                                                whiteSpace: 'normal'
                                                                            }}>{e.addr}</td>
                                                                            <td>{e.monthAttendList[0] === 10 ? '' : e.monthAttendList[0]}</td>
                                                                            <td>{e.monthAttendList[1] === 10 ? '' : e.monthAttendList[1]}</td>
                                                                            <td>{e.monthAttendList[2] === 10 ? '' : e.monthAttendList[2]}</td>
                                                                            <td>{e.monthAttendList[3] === 10 ? '' : e.monthAttendList[3]}</td>
                                                                            <td>{e.monthAttendList[4] === 10 ? '' : e.monthAttendList[4]}</td>
                                                                            <td>{e.monthAttendList[5] === 10 ? '' : e.monthAttendList[5]}</td>
                                                                            <td>{e.monthAttendList[6] === 10 ? '' : e.monthAttendList[6]}</td>
                                                                            <td>{e.monthAttendList[7] === 10 ? '' : e.monthAttendList[7]}</td>
                                                                            <td>{e.monthAttendList[8] === 10 ? '' : e.monthAttendList[8]}</td>
                                                                            <td>{e.monthAttendList[9] === 10 ? '' : e.monthAttendList[9]}</td>
                                                                            <td>{e.monthAttendList[10] === 10 ? '' : e.monthAttendList[10]}</td>
                                                                            <td>{e.monthAttendList[11] === 10 ? '' : e.monthAttendList[11]}</td>
                                                                            <td>{e.monthAttendList[12] === 10 ? '' : e.monthAttendList[12]}</td>
                                                                            <td>{e.monthAttendList[13] === 10 ? '' : e.monthAttendList[13]}</td>
                                                                            <td>{e.monthAttendList[14] === 10 ? '' : e.monthAttendList[14]}</td>
                                                                            <td>{e.monthAttendList[15] === 10 ? '' : e.monthAttendList[15]}</td>

                                                                            {/* 주차/년월차/추가 */}
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>

                                                                            <td className={'table-right'}>{e.day_amt.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax1.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax2.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax3.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax4.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax5.toLocaleString()}</td>
                                                                        </tr>
                                                                        <tr style={{position: 'relative'}}>
                                                                            <td>{e.job_position}</td>
                                                                            <td style={{textAlign: 'left'}}>{e.id_num}</td>
                                                                            <td>{e.monthAttendList[16] === 10 ? '' : e.monthAttendList[16]}</td>
                                                                            <td>{e.monthAttendList[17] === 10 ? '' : e.monthAttendList[17]}</td>
                                                                            <td>{e.monthAttendList[18] === 10 ? '' : e.monthAttendList[18]}</td>
                                                                            <td>{e.monthAttendList[19] === 10 ? '' : e.monthAttendList[19]}</td>
                                                                            <td>{e.monthAttendList[20] === 10 ? '' : e.monthAttendList[20]}</td>
                                                                            <td>{e.monthAttendList[21] === 10 ? '' : e.monthAttendList[21]}</td>
                                                                            <td>{e.monthAttendList[22] === 10 ? '' : e.monthAttendList[22]}</td>
                                                                            <td>{e.monthAttendList[23] === 10 ? '' : e.monthAttendList[23]}</td>
                                                                            <td>{e.monthAttendList[24] === 10 ? '' : e.monthAttendList[24]}</td>
                                                                            <td>{e.monthAttendList[25] === 10 ? '' : e.monthAttendList[25]}</td>
                                                                            <td>{e.monthAttendList[26] === 10 ? '' : e.monthAttendList[26]}</td>
                                                                            <td>{e.monthAttendList[27] === 10 ? '' : e.monthAttendList[27]}</td>
                                                                            <td>{e.monthAttendList[28] === 10 ? '' : e.monthAttendList[28]}</td>
                                                                            <td>{e.monthAttendList[29] === 10 ? '' : e.monthAttendList[29]}</td>
                                                                            <td>{e.monthAttendList[30] === 10 ? '' : e.monthAttendList[30]}</td>
                                                                            <td></td>
                                                                            <td>{e.attend_cnt}</td>
                                                                            <td colSpan={2}>{e.man_day}</td>
                                                                            <td className={'table-right'}>{e.salary_amt.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax6.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax7.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax8.toLocaleString()}</td>
                                                                            <td className={'table-right'}>{e.tax9.toLocaleString()}</td>
                                                                            <td className={'table-right'}
                                                                                style={{
                                                                                    borderRight: openReportInfo[i] && 'none',
                                                                                }}
                                                                            >{e.real_salary_amt.toLocaleString()}</td>
                                                                            {
                                                                                openReportInfo[i] &&
                                                                                <td className={'border'} style={{
                                                                                    position: 'absolute',
                                                                                    left: '32px',
                                                                                    bottom: '-44px',
                                                                                    boxSizing: 'border-box',
                                                                                    backgroundColor: '#d4e3ec',
                                                                                    zIndex: '999',
                                                                                    padding: '5px 15px',
                                                                                    border: '1px solid #CBD5E0'
                                                                                }}><TableInfo>
                                                                                    <span>입사일 : <em>{e.join_dt}</em></span>
                                                                                    <span>당월 출역일 : <em>{e.this_month_commute_cnt}</em></span>
                                                                                    <span>전월 출역일 : <em>{e.last_month_commute_cnt}</em></span>
                                                                                    <span>입사1개월간 출역일 : <em>{e.one_month_commute_cnt}</em></span>
                                                                                    <span>당월 총급여 : <em>{e.this_month_salary_amt.toLocaleString()}</em></span>
                                                                                    <span>전월 총급여 : <em>{e.last_month_salary_amt.toLocaleString()}</em></span>
                                                                                </TableInfo></td>
                                                                            }
                                                                        </tr>
                                                                    </React.Fragment>);
                                                                }
                                                            })}
                                                            </tbody>
                                                        </Table>
                                                    </TableWrapper>
                                                </TableContainer>
                                            </ClickAwayListener> :
                                            <EmptyContainer selected={false} falseText={`노무비지급명세서가`}
                                                            style={{height: '500px'}}> </EmptyContainer>
                                    }
                                    {/*<ClickAwayListener onClickAway={onClickAway}>*/}
                                    {/*    <TableContainer>*/}
                                    {/*        <TableWrapper>*/}
                                    {/*            <Table id={'report-table'}>*/}
                                    {/*                <thead>*/}
                                    {/*                <tr>*/}
                                    {/*                    <th rowSpan={2}>구분</th>*/}
                                    {/*                    <th rowSpan={3}>이름</th>*/}
                                    {/*                    <th rowSpan={2}>주소</th>*/}
                                    {/*                    <th colSpan={16}>출역사항</th>*/}
                                    {/*                    <th colSpan={3} rowSpan={2}>주차/년월차<br/>/추가</th>*/}
                                    {/*                    <th rowSpan={2}>단가</th>*/}
                                    {/*                    <th rowSpan={2}>소득세</th>*/}
                                    {/*                    <th rowSpan={2}>고용보험</th>*/}
                                    {/*                    <th rowSpan={2}>국민연금</th>*/}
                                    {/*                    <th rowSpan={2}>기타/노조</th>*/}
                                    {/*                    <th rowSpan={2}>식대</th>*/}
                                    {/*                </tr>*/}
                                    {/*                <tr>*/}
                                    {/*                    <th>1</th>*/}
                                    {/*                    <th>2</th>*/}
                                    {/*                    <th>3</th>*/}
                                    {/*                    <th>4</th>*/}
                                    {/*                    <th>5</th>*/}
                                    {/*                    <th>6</th>*/}
                                    {/*                    <th>7</th>*/}
                                    {/*                    <th>8</th>*/}
                                    {/*                    <th>9</th>*/}
                                    {/*                    <th>10</th>*/}
                                    {/*                    <th>11</th>*/}
                                    {/*                    <th>12</th>*/}
                                    {/*                    <th>13</th>*/}
                                    {/*                    <th>14</th>*/}
                                    {/*                    <th>15</th>*/}
                                    {/*                    <th className="border">16</th>*/}
                                    {/*                </tr>*/}
                                    {/*                <tr>*/}
                                    {/*                    <th>직종</th>*/}
                                    {/*                    <th>주민등록번호</th>*/}
                                    {/*                    <th>17</th>*/}
                                    {/*                    <th>18</th>*/}
                                    {/*                    <th>19</th>*/}
                                    {/*                    <th>20</th>*/}
                                    {/*                    <th>21</th>*/}
                                    {/*                    <th>22</th>*/}
                                    {/*                    <th>23</th>*/}
                                    {/*                    <th>24</th>*/}
                                    {/*                    <th>25</th>*/}
                                    {/*                    <th>26</th>*/}
                                    {/*                    <th>27</th>*/}
                                    {/*                    <th>28</th>*/}
                                    {/*                    <th>29</th>*/}
                                    {/*                    <th>30</th>*/}
                                    {/*                    <th>31</th>*/}
                                    {/*                    <th></th>*/}
                                    {/*                    <th colSpan={3}>총일수/공수</th>*/}
                                    {/*                    <th>노무비총액</th>*/}
                                    {/*                    <th>지방소득세</th>*/}
                                    {/*                    <th>건강보험</th>*/}
                                    {/*                    <th>요양보험</th>*/}
                                    {/*                    <th>공제계</th>*/}
                                    {/*                    <th>실지급액</th>*/}
                                    {/*                </tr>*/}
                                    {/*                </thead>*/}
                                    {/*                <tbody>*/}
                                    {/*                {report.attendList.length && report.attendList.map((e, i) => {*/}
                                    {/*                    if (i === report.attendList.length - 1) {*/}
                                    {/*                        return (<React.Fragment key={i}>*/}
                                    {/*                            <tr>*/}
                                    {/*                                <th colSpan={19} rowSpan={2}>총계</th>*/}
                                    {/*                                <th></th>*/}
                                    {/*                                <th></th>*/}
                                    {/*                                <th></th>*/}
                                    {/*                                <th className={'table-right'}>{e.day_amt.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax1.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax2.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax3.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax4.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax5.toLocaleString()}</th>*/}
                                    {/*                            </tr>*/}
                                    {/*                            <tr>*/}
                                    {/*                                <th>{e.attend_cnt}</th>*/}
                                    {/*                                <th colSpan={2}>{e.man_day}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.salary_amt.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax6.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax7.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax8.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.tax9.toLocaleString()}</th>*/}
                                    {/*                                <th className={'table-right'}>{e.real_salary_amt.toLocaleString()}</th>*/}
                                    {/*                            </tr>*/}
                                    {/*                        </React.Fragment>);*/}
                                    {/*                    } else {*/}
                                    {/*                        return (<React.Fragment key={i}>*/}
                                    {/*                            <tr>*/}
                                    {/*                                <td rowSpan={1}>{e.seq}</td>*/}
                                    {/*                                <td rowSpan={2} style={{cursor: 'pointer'}}*/}
                                    {/*                                    onClick={(event) => handleOpenInfo(e, i)}>{e.user_nm}</td>*/}
                                    {/*                                <td rowSpan={1} style={{*/}
                                    {/*                                    maxWidth: '200px',*/}
                                    {/*                                    minWidth: '200px',*/}
                                    {/*                                    textAlign: 'left',*/}
                                    {/*                                    wordWrap: 'break-word',*/}
                                    {/*                                    overflowWrap: 'break-word',*/}
                                    {/*                                    wordBreak: 'break-all',*/}
                                    {/*                                    whiteSpace: 'normal'*/}
                                    {/*                                }}>{e.addr}</td>*/}
                                    {/*                                <td>{e.monthAttendList[0] === 10 ? '' : e.monthAttendList[0]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[1] === 10 ? '' : e.monthAttendList[1]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[2] === 10 ? '' : e.monthAttendList[2]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[3] === 10 ? '' : e.monthAttendList[3]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[4] === 10 ? '' : e.monthAttendList[4]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[5] === 10 ? '' : e.monthAttendList[5]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[6] === 10 ? '' : e.monthAttendList[6]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[7] === 10 ? '' : e.monthAttendList[7]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[8] === 10 ? '' : e.monthAttendList[8]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[9] === 10 ? '' : e.monthAttendList[9]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[10] === 10 ? '' : e.monthAttendList[10]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[11] === 10 ? '' : e.monthAttendList[11]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[12] === 10 ? '' : e.monthAttendList[12]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[13] === 10 ? '' : e.monthAttendList[13]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[14] === 10 ? '' : e.monthAttendList[14]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[15] === 10 ? '' : e.monthAttendList[15]}</td>*/}

                                    {/*                                /!* 주차/년월차/추가 *!/*/}
                                    {/*                                <td></td>*/}
                                    {/*                                <td></td>*/}
                                    {/*                                <td></td>*/}

                                    {/*                                <td className={'table-right'}>{e.day_amt.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax1.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax2.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax3.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax4.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax5.toLocaleString()}</td>*/}
                                    {/*                            </tr>*/}
                                    {/*                            <tr style={{position: 'relative'}}>*/}
                                    {/*                                <td>{e.job_position}</td>*/}
                                    {/*                                <td style={{textAlign: 'left'}}>{e.id_num}</td>*/}
                                    {/*                                <td>{e.monthAttendList[16] === 10 ? '' : e.monthAttendList[16]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[17] === 10 ? '' : e.monthAttendList[17]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[18] === 10 ? '' : e.monthAttendList[18]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[19] === 10 ? '' : e.monthAttendList[19]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[20] === 10 ? '' : e.monthAttendList[20]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[21] === 10 ? '' : e.monthAttendList[21]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[22] === 10 ? '' : e.monthAttendList[22]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[23] === 10 ? '' : e.monthAttendList[23]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[24] === 10 ? '' : e.monthAttendList[24]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[25] === 10 ? '' : e.monthAttendList[25]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[26] === 10 ? '' : e.monthAttendList[26]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[27] === 10 ? '' : e.monthAttendList[27]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[28] === 10 ? '' : e.monthAttendList[28]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[29] === 10 ? '' : e.monthAttendList[29]}</td>*/}
                                    {/*                                <td>{e.monthAttendList[30] === 10 ? '' : e.monthAttendList[30]}</td>*/}
                                    {/*                                <td></td>*/}
                                    {/*                                <td>{e.attend_cnt}</td>*/}
                                    {/*                                <td colSpan={2}>{e.man_day}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.salary_amt.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax6.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax7.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax8.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}>{e.tax9.toLocaleString()}</td>*/}
                                    {/*                                <td className={'table-right'}*/}
                                    {/*                                    style={{*/}
                                    {/*                                        borderRight: openReportInfo[i] && 'none',*/}
                                    {/*                                    }}*/}
                                    {/*                                >{e.real_salary_amt.toLocaleString()}</td>*/}
                                    {/*                                {*/}
                                    {/*                                    openReportInfo[i] &&*/}
                                    {/*                                    <td className={'border'} style={{*/}
                                    {/*                                        position: 'absolute',*/}
                                    {/*                                        left: '32px',*/}
                                    {/*                                        bottom: '-44px',*/}
                                    {/*                                        boxSizing: 'border-box',*/}
                                    {/*                                        backgroundColor: '#d4e3ec',*/}
                                    {/*                                        zIndex: '999',*/}
                                    {/*                                        padding: '5px 15px',*/}
                                    {/*                                        border: '1px solid #CBD5E0'*/}
                                    {/*                                    }}><TableInfo>*/}
                                    {/*                                        <span>입사일 : <em>{e.join_dt}</em></span>*/}
                                    {/*                                        <span>당월 출역일 : <em>{e.this_month_commute_cnt}</em></span>*/}
                                    {/*                                        <span>전월 출역일 : <em>{e.last_month_commute_cnt}</em></span>*/}
                                    {/*                                        <span>입사1개월간 출역일 : <em>{e.one_month_commute_cnt}</em></span>*/}
                                    {/*                                        <span>당월 총급여 : <em>{e.this_month_salary_amt.toLocaleString()}</em></span>*/}
                                    {/*                                        <span>전월 총급여 : <em>{e.last_month_salary_amt.toLocaleString()}</em></span>*/}
                                    {/*                                    </TableInfo></td>*/}
                                    {/*                                }*/}
                                    {/*                            </tr>*/}
                                    {/*                        </React.Fragment>);*/}
                                    {/*                    }*/}
                                    {/*                })}*/}
                                    {/*                </tbody>*/}
                                    {/*            </Table>*/}
                                    {/*        </TableWrapper>*/}
                                    {/*    </TableContainer>*/}
                                    {/*</ClickAwayListener>*/}

                                </Board>
                                :
                                <EmptyContainer selected={true} falseText={`노무비지급명세서가`}> </EmptyContainer>
                    }

                </Content>

                <Modal
                    opend={opendExport}
                    closeModal={() => setOpendExport(false)}
                    header={'출력 미리보기'}
                    okText={'출력'}
                    handleOk={() => {
                        handlePrint();
                    }}
                    widthCheck={'70%'}
                >
                    <PaymentStatementPagePrint
                        ref={componentRef}
                        data={report}
                        belong={belongFilter}
                        siteSeq={selectedSite}
                    />
                </Modal>
            </div>
        </AxiosNavigate>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }
`;

const Info = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        flex-direction: column;
    }
`;

const CalendarContainer = styled.div`
    width: 100%;
    padding: 20px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    @media only screen and (min-width: 768px) {
        width: 30%;
    }

    @media only screen and (min-width: 992px) {
        width: 280px;
    }
`;


const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-height: 37px;
    height: 37px;
    gap: 9px;
    border: 1.125px solid #E2E8F0;
    border-radius: 6px;
    background: #FFF;
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;

    leading-trim: both;
    text-edge: cap;
    // margin-top: 5px;
`;

const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;

const SiteSearch = styled.div`
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 12px;
    background: #F7FAFC;
    border-radius: 8px;
`;

const SiteSearchIcon = styled.img`
    padding-left: 12px;
`;

const SiteSearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    background: #F7FAFC;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const SiteList = styled.div`
    overflow: auto;
    height: 80%;
`;

const SiteItem = styled.div`
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 8px;
    cursor: pointer;

    :hover {
        background: rgba(0, 0, 0, 0.05);
    }
`;

const SiteEmptyList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
        color: #A0AEC0;
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: 0.16px;
        text-transform: uppercase;
    }
`;

const EmptyReport = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
        margin: 0;
        padding: 0;
        color: #A0AEC0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 992px) {
        min-height: 333px;
        margin-top: 24px;
    }
`;

const EmptyIcon = styled.img`
`;

const Board = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    margin-top: 24px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;


const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
    height: 74px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    background: #fff;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;

const BoardButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;

    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;
    margin-top: 5px;

`;
const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 4px;
        font-size: 12px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #e2e8f0;
        text-align: center;
    }

    tr:last-child td {
        border-bottom: none;
    }

    td {
        background: #fff;
        text-align: center;
    }

`;

const PageGroup = styled.div`
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        padding: 0;
        list-style: none;
    }

    li a {
        display: inline-block;
        cursor: pointer;
        color: #000;
        padding: 6px 12px;
        text-decoration: none;
        font-size: 12px;
    }

    li.active a {
        color: #fff;
        background-color: #3279F5 !important;
        border-radius: 4px;
    }

    li:hover:not(.active) a {
        background-color: #EBF2FE !important;
        border-radius: 4px;
    }
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;

const BoardHeader = styled.div`
    //margin-bottom: 14px;
    //margin-top: 28px;
    padding: 0 32px;
    // margin-bottom: -20px;
    // height: 60px;

    h2, h5 {
        margin: 0;
    }

    h5 {
        margin-top: 4px;
        margin-bottom: -20px;
    }
`;
const TableInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    justify-items: start;
    row-gap: 5px;


`;

export default LaborPaymentStatement;