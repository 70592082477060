import React, {forwardRef} from 'react';
import styled from '@emotion/styled';


const LaborContractPagePrint = forwardRef((props, ref) => {
    const data = props.data;

    const createMarkup = (content) => {
        return {__html: content};
    };
    return (
        <Container ref={ref}>
            <Contract dangerouslySetInnerHTML={createMarkup(data.contract)}></Contract>
            <Footer>
                <span className={'box'}>위의 근로계약 조건을 확인하였습니다.</span>
                <span className={'date'} style={{float: 'right'}}>{data?.join_dt}</span>
                <InfoWrap>
                    <div>
                        <em>사업주</em>
                        <span>{data?.ceo_nm}</span>
                    </div>
                    <img src={data?.company_stamp} alt="company_stamp"/>
                </InfoWrap>
                <InfoWrap style={{borderStyle: 'none', paddingTop: '10px'}}>
                    <div>
                        <em>근로자</em>
                        <span>{data?.user_nm}</span>
                    </div>
                    <img style={{width: '200px', height: '100px'}} src={data?.user_sign} alt="user_sign"/>
                </InfoWrap>

            </Footer>
        </Container>
    );
});

const Container = styled.div`
    // border: 1px solid #E2E8F0;
    background: #FFF;
    padding: 20px 24px;

    @page {
        size: A4 portrait !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    @media print {
        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }

        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }
    }

    //p {
    //    margin: 0 0 3px 0;
    //}
    //
    //p:first-of-type strong {
    //    font-size: 27px;
    //    font-weight: bold;
    //}
    //
    //strong {
    //    font-size: 19px;
    //}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;


const Contract = styled.div`
    //font-size: 13px;
`;

const InfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #E2E8F0;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        em {
            font-weight: 500;
            font-size: 19px;
        }

        span {
            font-weight: 350;
            font-size: 13px;
        }

        //justify-content: space-around;
    }

    img {
        width: 100px;
        height: 100px;
    }
`;

const Footer = styled.div`
    span.box {
        padding: 10px 22px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: #EDF2F7;
        width: 100%;
        display: block;
        font-weight: normal;
        margin-top: 25px;
    }

    > span {
        font-size: 14px;
    }

    .date {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }
`;

const UserWrap = styled.div`

`;

export default LaborContractPagePrint;
